@charset 'utf-8';
.p-h3title{
    &::after{
        content: "";
        display: block;
        margin-top: 0.3rem;
        margin-bottom: -1px;
        width: 20%;
        height: 2px;
        background: transparent linear-gradient(180deg, #AFCADF 0%, #5E7F95 100%) 
    }

    position: relative;
    font-size: 1.25rem; //20px
    font-weight: bold;
    color: #707070;
    border-bottom: 1px solid #707070;
    margin-bottom: 0.5rem;
}