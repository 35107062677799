@charset 'utf-8';
.p-h2title{
    &::after{
        content: "";
        display: block;
        width: 1em;
        height: 3em;
        margin-top: -2.2em;
        background:no-repeat url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTkiIGhlaWdodD0iNjgiIHZpZXdCb3g9IjAgMCAxOSA2OCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6cmdiYSgxOCwxMywxMywwLjI3KTt9LmJ7ZmlsbDp1cmwoI2EpO308L3N0eWxlPjxsaW5lYXJHcmFkaWVudCBpZD0iYSIgeDE9IjAuNSIgeDI9IjAuNSIgeTI9IjEiIGdyYWRpZW50VW5pdHM9Im9iamVjdEJvdW5kaW5nQm94Ij48c3RvcCBvZmZzZXQ9IjAiIHN0b3AtY29sb3I9IiNhZmNhZGYiLz48c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiNlMmYwZjkiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDEpIj48cmVjdCBjbGFzcz0iYSIgd2lkdGg9IjEyIiBoZWlnaHQ9IjYyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0IDIpIi8+PHBhdGggY2xhc3M9ImIiIGQ9Ik0wLDBIMTJWNjJIMFoiLz48L2c+PC9zdmc+");
        background-size: contain;
    }

    position: relative;
    font-size: 1.25rem; //20px
    font-weight: bold;
    color: #707070;
    text-indent: 1em;
    margin-bottom: 0.5rem;
}