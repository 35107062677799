@charset "utf-8";
.c-link{
    position: relative;    
}
.c-link-a{
    display: block;
    width: 100%;
    height: 100%;
}
.c-link-text{
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
    top: 50%;
}