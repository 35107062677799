@charset "utf-8";
#companypage{
    table{
        margin: 0 auto;
    }
    #invention{
        position: relative;
        margin-bottom: 35px;
        #invention-copy{
            position: absolute;
            bottom:50px;
            left: 80px;
        }
    }
}