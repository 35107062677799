@charset 'utf-8';
.p-footer{
    position: fixed;
    bottom: 0;
    right: 0;
    background: rgba($color: #fff, $alpha: 0.5);
    p{
        color: #333;
        font-size: 0.8rem;
    }
}