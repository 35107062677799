.p-infoaddress {
    box-sizing: border-box;
    display: block;
    width: 320px;
    height: 7rem;
    position: absolute;
    top:80%;
    right: 10%;
    @media(max-width:960px){
        display: none;
    }
    background: #fff;
    box-shadow: 7px 9px 3px -6px rgba(0,0,0,0.39);
    padding: 2rem 2rem;
    h3{
        margin-bottom: 0.5rem;
    }
    p{
        text-align: center;
    }
}