@charset "UTF-8";

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

html {
  width: 100%;
  overflow-x: hidden;
}

body {
  width: 100%;
  overflow-x: hidden;
  font-size: 16px;
}

a {
  color: #707070;
  text-decoration: none;
}

a[target=_blank]::after {
  font-family: "Font Awesome 5 Free";
  content: "\f35d";
  font-size: 50%;
  font-weight: 900;
  margin: 0 3px;
}

a:visited {
  color: #505050;
}

h1 {
  font-size: 2rem;
  letter-spacing: 0.05rem;
}

h2 {
  font-size: 1.5rem;
  margin: 1rem 0;
  line-height: 2rem;
  letter-spacing: 0.05rem;
}

h3 {
  font-size: 1rem;
  line-height: 1.8rem;
  letter-spacing: 0.05rem;
  font-weight: bolder;
}

h4 {
  font-weight: bolder;
  margin-bottom: 0.5rem;
}

p {
  font-size: 1rem;
  letter-spacing: 0.03rem;
  line-height: 1.5rem;
  text-align: justify;
  text-justify: inter-character;
  font-feature-settings: "palt";
  letter-spacing: -0.02em;
}

table td {
  border-bottom: 1px solid #eee;
  line-height: 1.5rem;
  padding: 0.5rem;
  height: 1.1rem;
}
table tr:hover {
  background: #f1f1f1;
}

ul li {
  line-height: 1.5rem;
  list-style: inside disc none;
}

.zen-maru-gothic-regular {
  font-family: "Zen Maru Gothic", serif;
  font-weight: 400;
  font-style: normal;
}

.zen-maru-gothic-black {
  font-family: "Zen Maru Gothic", serif;
  font-weight: 900;
  font-style: normal;
}

#contents {
  width: 100%;
  min-height: 100vh;
  background: url(../../img/toppage/universe603.png) bottom right no-repeat fixed;
  background-size: cover;
}
@media screen and (max-width: 768px) {
  #contents {
    background-position: top left;
  }
}

#options {
  width: 297px;
  position: absolute;
  top: 0;
  right: 15px;
}
#options ul {
  display: flex;
}
#options ul li {
  width: 141px;
  margin-right: 15px;
  opacity: 0.8;
  list-style: none;
}
#options ul li:hover {
  opacity: 1;
  transition: 0.5s all;
}
@media screen and (max-width: 768px) {
  #options {
    display: none;
  }
}

#logoblock {
  width: 35%;
  aspect-ratio: 1/0.4;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.5) 0% 0% no-repeat padding-box;
  border-radius: 0px 5px 5px 0px;
  backdrop-filter: blur(36px);
  -webkit-backdrop-filter: blur(36px);
  position: absolute;
  top: 50%;
  transform: translateY(-70%);
  padding-top: 30px;
}
@media screen and (max-width: 768px) {
  #logoblock {
    width: 100%;
    height: auto;
    border-radius: 0;
    padding: 30px 0;
  }
}
#logoblock ::before {
  display: block;
  content: "";
  width: calc(100% - 30px);
  height: calc(100% - 40px);
  border: 1px solid #FFFFFF;
  position: absolute;
  top: 20px;
  left: 15px;
}
@media screen and (max-width: 768px) {
  #logoblock ::before {
    display: none;
  }
}
#logoblock h1 {
  display: block;
  width: 100%;
  height: 100%;
}
#logoblock img {
  display: block;
  height: calc(100% - 40px);
  margin: 0 auto;
}

#mainnavi {
  margin-top: 80vh;
}
#mainnavi ul {
  margin: 0 auto;
  width: 84.5%;
  display: flex;
  background: rgba(255, 255, 255, 0.89) 0% 0% no-repeat padding-box;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
}
#mainnavi ul li {
  display: block;
  width: 14.29%;
  height: 143px;
}
#mainnavi ul li a {
  font-size: 1.75rem;
  display: grid;
  justify-items: center;
  align-items: center;
  width: 100%;
  height: 143px;
  text-align: center;
  position: relative;
  transition: 0.5s all;
}
#mainnavi ul li a::after {
  content: "";
  display: block;
  width: 0px;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 50%;
  background: #B157FF;
}
#mainnavi ul li a.now::after {
  content: "";
  display: block;
  width: 0;
  height: 6px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #B157FF;
}
#mainnavi ul li a:hover {
  background: rgba(255, 255, 255, 0.95);
  position: relative;
  transition: 0.3s all;
}
#mainnavi ul li a:hover::after {
  content: "";
  display: block;
  width: 100%;
  height: 6px;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 0.3s all ease-out;
  background: #B157FF;
}
@media screen and (max-width: 768px) {
  #mainnavi ul {
    width: 100%;
    flex-wrap: wrap;
  }
  #mainnavi ul li {
    width: 50%;
    height: 60px;
  }
  #mainnavi ul li a {
    font-size: 1.75rem;
    display: block;
    width: 100%;
    height: 60px;
    line-height: 60px;
    text-align: center;
    position: relative;
    transition: 0.5s all;
  }
  #mainnavi ul li a::after {
    content: "";
    display: block;
    width: 0;
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 50%;
    background: #B157FF;
  }
  #mainnavi ul li a.now::after {
    content: "";
    display: block;
    width: 0;
    height: 6px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #B157FF;
  }
  #mainnavi ul li a:hover {
    background: rgba(255, 255, 255, 0.95);
    position: relative;
    transition: 0.5s all;
  }
  #mainnavi ul li a:hover::after {
    content: "";
    display: block;
    width: 0;
    height: 6px;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: 0.3s all ease-out;
    background: #B157FF;
  }
}

#sakuhin-sideimage img {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-height: 100%;
}

#companypage table {
  margin: 0 auto;
}
#companypage #invention {
  position: relative;
  margin-bottom: 35px;
}
#companypage #invention #invention-copy {
  position: absolute;
  bottom: 50px;
  left: 80px;
}

#servicepage .l-ourphoto {
  position: relative;
  width: 100%;
  margin-top: 5rem;
}
#servicepage .l-ourphoto img {
  display: block;
  width: 48%;
}
#servicepage .l-ourphoto img:nth-child(2n+1) {
  margin: 0 0 0 0;
}
#servicepage .l-ourphoto img:nth-child(2n) {
  margin: -100px 0 0 auto;
}

#productspage .l-items {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

#contactpage .c-pagesplit-right p {
  font-family: "Zen Maru Gothic", serif;
  font-weight: 400;
  font-style: normal;
  margin: 2em 0.5em;
}
#contactpage .c-pagesplit-right ul {
  margin-left: 1em;
}
#contactpage .c-pagesplit-right ul li {
  font-family: "Zen Maru Gothic", serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 0.3em;
  text-indent: -1em;
  padding-left: 1em;
}
#contactpage .c-pagesplit-right .conv {
  display: flex;
  width: fit-content;
  margin: 0 auto 0;
  align-items: baseline;
  justify-content: space-around;
  padding: 0.5em 2em;
  background: #ffe48a;
  border-radius: 30px;
  font-size: 1.5em;
}
#contactpage .c-pagesplit-right .conv a, #contactpage .c-pagesplit-right .conv p {
  margin: 0;
}
#contactpage .c-pagesplit-right .conv .wrap {
  display: block;
  width: fit-content;
}
#contactpage .c-pagesplit-right .conv .notice {
  margin-top: 1em;
  font-size: 0.5em;
}

.c-link {
  position: relative;
}

.c-link-a {
  display: block;
  width: 100%;
  height: 100%;
}

.c-link-text {
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
  top: 50%;
}

.c-pagesplit {
  width: 100%;
  margin-top: 0;
  display: flex;
}
@media (max-width: 960px) {
  .c-pagesplit {
    display: block;
  }
}
.c-pagesplit .c-pagesplit-left, .c-pagesplit .c-pagesplit-right {
  width: 30%;
  box-sizing: border-box;
  padding: 35px;
  position: relative;
}
@media (max-width: 960px) {
  .c-pagesplit .c-pagesplit-left, .c-pagesplit .c-pagesplit-right {
    width: 100%;
    padding: 7px;
  }
}
.c-pagesplit .c-pagesplit-right {
  container: maincontents/inline-size;
  width: 70%;
}
@container maincontents (width > 1000px) {
  .c-pagesplit .c-pagesplit-right .wrapper {
    width: 70%;
    margin: 0 auto 0 0;
  }
}
@media (max-width: 960px) {
  .c-pagesplit .c-pagesplit-right {
    width: 100%;
    padding: 7px;
  }
}
.c-pagesplit .c-pagesplit-right pre {
  overflow: hidden;
}
.c-pagesplit .c-pagesplit-left {
  background: url(../img/common/split-bg.png) #f4f9ff bottom right no-repeat;
  background-size: cover;
  min-height: 100vh;
}
@media (max-width: 960px) {
  .c-pagesplit .c-pagesplit-left {
    min-height: inherit;
  }
}
@media (max-width: 960px) {
  .c-pagesplit img {
    width: 100%;
  }
}

.c-content-width {
  max-width: 1543px;
  margin: 0 auto;
}

.p-pageheader {
  background-color: #f9f9f9;
}
.p-pageheader #options {
  display: flex;
}
.p-pageheader section {
  padding: 34px 0;
}
.p-pageheader section .header-logo {
  display: block;
  width: 256px;
  height: 61px;
  background: url(../../img/common/ANlogomain-small.png);
}
.p-pageheader .menu {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.29);
  position: relative;
  z-index: 100;
}
.p-pageheader .menu ul {
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
}
@media (max-width: 960px) {
  .p-pageheader .menu ul {
    flex-wrap: wrap;
  }
}
.p-pageheader .menu ul li {
  display: block;
  width: 12.5%;
  height: 70px;
  border-right: 1px solid #eee;
}
@media (max-width: 1624px) {
  .p-pageheader .menu ul li {
    width: 100%;
  }
}
@media (max-width: 960px) {
  .p-pageheader .menu ul li {
    width: 100%;
  }
}
.p-pageheader .menu ul li:first-child {
  border-left: 1px solid #eee;
}
.p-pageheader .menu ul li a {
  font-size: 1rem;
  display: block;
  width: 100%;
  height: 70px;
  line-height: 70px;
  text-align: center;
  position: relative;
  transition: 0.5s all;
}
@media (max-width: 1624px) {
  .p-pageheader .menu ul li a {
    width: 100%;
  }
}
.p-pageheader .menu ul li a::after {
  content: "";
  display: block;
  width: 0px;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 50%;
  background: #B157FF;
}
.p-pageheader .menu ul li a.now::after {
  content: "";
  display: block;
  width: 100%;
  height: 6px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #B157FF;
}
.p-pageheader .menu ul li a:hover {
  background: rgba(255, 255, 255, 0.95);
  position: relative;
  transition: 0.5s all;
}
.p-pageheader .menu ul li a:hover::after {
  content: "";
  display: block;
  width: 100%;
  height: 6px;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 0.3s all ease-out;
  background: #B157FF;
}

.p-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.5);
}
.p-footer p {
  color: #333;
  font-size: 0.8rem;
}

.p-pagetitle {
  width: 100%;
  max-height: 300px;
  position: relative;
  font-size: 3rem;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.39);
  opacity: 1;
  line-height: 5rem;
}
.p-pagetitle::before {
  content: "";
  display: block;
  background: url(../../img/common/title-bg.png) no-repeat;
  background-size: contain;
  width: 100%;
  max-width: 398px;
  aspect-ratio: 1/1;
}
@media (max-width: 960px) {
  .p-pagetitle::before {
    padding-top: 0px;
    width: 200px;
    height: 200px;
    background-size: contain;
  }
}
@media (max-width: 960px) {
  .p-pagetitle {
    font-size: 2rem;
    line-height: 2rem;
    padding-top: 0;
  }
}
.p-pagetitle span {
  position: absolute;
  top: 50%;
  left: 10%;
}
@media (max-width: 960px) {
  .p-pagetitle span {
    top: 82px;
    left: 74px;
  }
}

.p-h2title {
  position: relative;
  font-size: 1.25rem;
  font-weight: bold;
  color: #707070;
  text-indent: 1em;
  margin-bottom: 0.5rem;
}
.p-h2title::after {
  content: "";
  display: block;
  width: 1em;
  height: 3em;
  margin-top: -2.2em;
  background: no-repeat url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTkiIGhlaWdodD0iNjgiIHZpZXdCb3g9IjAgMCAxOSA2OCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6cmdiYSgxOCwxMywxMywwLjI3KTt9LmJ7ZmlsbDp1cmwoI2EpO308L3N0eWxlPjxsaW5lYXJHcmFkaWVudCBpZD0iYSIgeDE9IjAuNSIgeDI9IjAuNSIgeTI9IjEiIGdyYWRpZW50VW5pdHM9Im9iamVjdEJvdW5kaW5nQm94Ij48c3RvcCBvZmZzZXQ9IjAiIHN0b3AtY29sb3I9IiNhZmNhZGYiLz48c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiNlMmYwZjkiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDEpIj48cmVjdCBjbGFzcz0iYSIgd2lkdGg9IjEyIiBoZWlnaHQ9IjYyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0IDIpIi8+PHBhdGggY2xhc3M9ImIiIGQ9Ik0wLDBIMTJWNjJIMFoiLz48L2c+PC9zdmc+");
  background-size: contain;
}

.p-h3title {
  position: relative;
  font-size: 1.25rem;
  font-weight: bold;
  color: #707070;
  border-bottom: 1px solid #707070;
  margin-bottom: 0.5rem;
}
.p-h3title::after {
  content: "";
  display: block;
  margin-top: 0.3rem;
  margin-bottom: -1px;
  width: 20%;
  height: 2px;
  background: transparent linear-gradient(180deg, #AFCADF 0%, #5E7F95 100%);
}

.p-newsheadline {
  color: #707070;
}
.p-newsheadline li {
  list-style: none;
  padding-left: 7rem;
}
.p-newsheadline li p {
  position: relative;
}
.p-newsheadline li span {
  position: absolute;
  margin-left: 0;
  font-weight: bold;
  left: -7rem;
}

.p-productbox {
  width: 193px;
  height: 255px;
  margin-left: 27px;
  margin-bottom: 27px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1607843137);
  opacity: 1;
  border-radius: 5px;
}
.p-productbox img {
  border-bottom: 3px solid #B157FF;
  border-radius: 5px 5px 0 0;
}
.p-productbox p {
  font-size: 1rem;
  font-weight: bold;
  padding: 8px 10px;
  text-align: justify;
  text-justify: inter-character;
  font-feature-settings: "palt";
  letter-spacing: -0.02em;
}

.p-infoaddress {
  box-sizing: border-box;
  display: block;
  width: 320px;
  height: 7rem;
  position: absolute;
  top: 80%;
  right: 10%;
  background: #fff;
  box-shadow: 7px 9px 3px -6px rgba(0, 0, 0, 0.39);
  padding: 2rem 2rem;
}
@media (max-width: 960px) {
  .p-infoaddress {
    display: none;
  }
}
.p-infoaddress h3 {
  margin-bottom: 0.5rem;
}
.p-infoaddress p {
  text-align: center;
}

.u-modalwindow_overlay {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.u-modalwindow {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
  height: 70%;
  z-index: 9999;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 1px solid hsl(0deg, 0%, 83%);
  border-radius: 20px;
  box-shadow: 0 2.5rem 2rem -2rem hsla(20deg, 50%, 20%, 0.4);
  transform: translate(-50%, -50%);
}

.u-modalwindow div:nth-child(1) {
  width: 70%;
  height: 100%;
}
@media screen and (max-width: 1000px) {
  .u-modalwindow div:nth-child(1) {
    width: 100%;
  }
}

.u-modalwindow div:nth-child(2) {
  width: 30%;
}
@media screen and (max-width: 1000px) {
  .u-modalwindow div:nth-child(2) {
    width: 100%;
  }
}

.u-mb {
  margin-bottom: 1rem;
}

.u-mb-l {
  margin-bottom: 3rem;
}

.u-mb-xl {
  margin-bottom: 10rem;
}

.u-spantitle {
  font-weight: bold;
  padding-right: 2em;
}