@charset "utf-8";
#servicepage{
    .l-ourphoto{
        position: relative;
        width: 100%;
        margin-top: 5rem;
        img{
            display: block;
            width: 48%;
            &:nth-child(2n+1){
                margin:0 0 0 0;
            }
            &:nth-child(2n){
                margin: -100px 0 0 auto;
            }
        }
    }
}