@charset 'utf-8';
#contents{
    width: 100%;
    min-height: 100vh;
    background: url(../../img/toppage/universe603.png) bottom right no-repeat fixed;
    background-size: cover;
    @include mq(md){
        background-position: top left;
    }
}
#options{
    width: 297px;
    position: absolute;
    top: 0;
    right: 15px;
    ul{
        display: flex;
        li{
            width: 141px;
            margin-right:15px;
            opacity: 0.8;
            list-style: none;
            &:hover{
                opacity:1;
                transition: 0.5s all;
            }
        }
    }
    @include mq(md){
        display:none;
    }
}
#logoblock {
    width: 35%;
    aspect-ratio: 1/0.4;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.5) 0% 0% no-repeat padding-box;
    border-radius: 0px 5px 5px 0px;
    backdrop-filter: blur(36px);
    -webkit-backdrop-filter: blur(36px);
    position: absolute;
    top:50%;
    transform: translateY(-70%);
    padding-top: 30px;
    @include mq(md){
        width: 100%;
        height: auto;
        border-radius: 0;
        padding: 30px 0;
    }
    ::before {
        display: block;
        content: "";
        width: calc( 100% - 30px );
        height: calc( 100% - 40px );
        // aspect-ratio: 1/0.4;
        border: 1px solid #FFFFFF;
        position: absolute;
        top: 20px;
        left: 15px;
        @include mq(md){
            display: none;
        }
    }
    h1{
        display: block;
        width: 100%;
        height: 100%;
    }
    img {
        display: block;
        height: calc( 100% - 40px);
        margin: 0 auto;
    }
}

#mainnavi {
    margin-top: 80vh;
    ul {
        margin: 0 auto;
        width: 84.5%;
        display: flex;
        background: rgba(255,255,255,0.89) 0% 0% no-repeat padding-box;
        backdrop-filter: blur(50px);
        -webkit-backdrop-filter: blur(50px);
        
        li {
            display: block;
            width: 14.29%;
            height: 143px;
            a {
                font-size: 1.75rem;
                display: grid;
                justify-items: center;
                align-items: center;
                width:100%;
                height: 143px;
                text-align: center;
                position: relative;
                transition: 0.5s all;
                &::after{
                    content: "";
                    display: block;
                    width: 0px;
                    height: 1px;
                    position:absolute;
                    bottom: 0;
                    left:50%;
                    background: #B157FF;
                }
                &.now::after{
                    content: "";
                    display: block;
                    width: 0;
                    height: 6px;
                    position:absolute;
                    bottom: 0;
                    left:0;
                    background: #B157FF;   
                }
                &:hover{
                    background: rgba(255,255,255,0.95);
                    position: relative;
                    transition: 0.3s all;
                    &::after{
                        content: "";
                        display: block;
                        width: 100%;
                        height: 6px;
                        position:absolute;
                        bottom: 0;
                        left:0;
                        transition: 0.3s all ease-out;
                        background: #B157FF;
                    }
                }
            }
        }
        @include mq(md){
            width:100%;
            flex-wrap: wrap;
            li{
                width: 50%;
                height: 60px;              
                a {
                    font-size: 1.75rem;
                    display: block;
                    width: 100%;
                    height: 60px;
                    line-height: 60px;
                    text-align: center;
                    position: relative;
                    transition: 0.5s all;
                    &::after{
                        content: "";
                        display: block;
                        width: 0;
                        height: 3px;
                        position:absolute;
                        bottom: 0;
                        left:50%;
                        background: #B157FF;
                    }
                    &.now::after{
                        content: "";
                        display: block;
                        width: 0;
                        height: 6px;
                        position:absolute;
                        bottom: 0;
                        left:0;
                        background: #B157FF;   
                    }
                    &:hover{
                        background: rgba(255,255,255,0.95);
                        position: relative;
                        transition: 0.5s all;
                        &::after{
                            content: "";
                            display: block;
                            width: 0;
                            height: 6px;
                            position:absolute;
                            bottom: 0;
                            left:0;
                            transition: 0.3s all ease-out;
                            background: #B157FF;
                        }
                    }
                }
            }
            
        }
        
    }
}


#sakuhin-sideimage img {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-height: 100%;
  }