@charset 'utf-8';
.c-pagesplit{
    
    width: 100%;
    margin-top: 0;
    display: flex;
    @media(max-width:960px){
        display: block;
    }
    .c-pagesplit-left,.c-pagesplit-right{
        width: 30%;
        box-sizing: border-box;
        padding: 35px;
        position: relative;
        @media(max-width:960px){
            width: 100%;
            padding: 7px;
        }
    }
    .c-pagesplit-right{
        width: 70%;
        @media(max-width:960px){
            width: 100%;
            padding: 7px;
        }
        & pre{
            overflow:hidden;
        }
    }
    .c-pagesplit-left{
        background: url(../img/common/split-bg.png) #f4f9ff bottom right no-repeat;
        background-size: cover;
        min-height: 100vh;
        @media(max-width:960px){
            min-height: inherit;
        }
    }
    @media(max-width:960px){
        img{
            width: 100%;
        }
    }
}