@charset "utf-8";
*{box-sizing: border-box;}
html {
    width: 100%;
    overflow-x: hidden;
}
body {
    width: 100%;
    overflow-x: hidden;
    font-size: 16px;
}

a {
    color: #707070;
    text-decoration: none;
}
a[target="_blank"]::after {
    font-family: "Font Awesome 5 Free";
    content: '\f35d';
    font-size: 50%;
    font-weight: 900;
    margin: 0 3px;
}

a:visited {
    color: #505050;
}

h1 {
    font-size: 2.0rem;
    letter-spacing: 0.05rem;
}

h2 {
    font-size: 1.5rem;
    margin: 1rem 0;
    line-height: 2rem;
    letter-spacing: 0.05rem;
}

h3 {
    font-size: 1.0rem;
    line-height: 1.8rem;
    letter-spacing: 0.05rem;
    font-weight: bolder;
}
h4{
    font-weight: bolder;
    margin-bottom: 0.5rem;
}

p {
    font-size: 1.0rem;
    letter-spacing: 0.03rem;
    line-height: 1.5rem;
    text-align: justify;
    text-justify: inter-character;
    font-feature-settings: "palt";
    letter-spacing: -0.02em;
}

table {
    td {
        border-bottom:1px solid #eee;
        line-height: 1.5rem;
        padding: 0.5rem;
        height: 1.1rem;
    }
    tr:hover{
        background: #f1f1f1;
    }
}

ul li{
    line-height: 1.5rem;
    list-style: inside disc none;
}
.zen-maru-gothic-regular {
    font-family: "Zen Maru Gothic", serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .zen-maru-gothic-black {
    font-family: "Zen Maru Gothic", serif;
    font-weight: 900;
    font-style: normal;
  }
  