#contactpage{
  .c-pagesplit-right{
    p{
    font-family: "Zen Maru Gothic", serif;
    font-weight: 400;
    font-style: normal;
    margin: 2em 0.5em;
  }
  ul{
    margin-left: 1em;
  }
  ul li{
    font-family: "Zen Maru Gothic", serif;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 0.3em;
    text-indent: -1em;
    padding-left: 1em;
  }
  .conv{
    display: flex;
    width: fit-content;
    margin: 0 auto 0;
    align-items: baseline;
    justify-content: space-around;
    padding: 0.5em 2em;
    background: #ffe48a;
    border-radius: 30px;
    font-size: 1.5em;
    a,p{ margin: 0;}
    .wrap{
      display: block;
      width: fit-content;
    }
    .notice{
      margin-top: 1em;
      font-size: 0.5em;
    }
  }
  }
}