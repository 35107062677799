@charset 'utf-8';
.u-modalwindow_overlay{
    content:"";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.8);

}
.u-modalwindow{
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    background: rgba($color: #fff, $alpha: 0.8);
    position: absolute;
    top:50%;
    left: 50%;
    width: 70%;
    height: 70%;
    z-index: 9999;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border: 1px solid hsl(0,0%, 83%);
    border-radius: 20px;
    box-shadow: 0 2.5rem 2rem -2rem hsla(20, 50%, 20%,40%);
    transform: translate(-50%,-50%);
}
.u-modalwindow div:nth-child(1){
    width: 70%;
    height: 100%;
    @include mq(lg){
        width: 100%;
    }

}
.u-modalwindow div:nth-child(2){
    width: 30%;
    @include mq(lg){
        width: 100%;
    }
}
    
