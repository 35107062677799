@charset "utf-8";
.p-pageheader{
    background-color: #f9f9f9;
    #options{
        display: flex;
    }
    section{
        padding: 34px 0;
        .header-logo{
            display: block;
            width: 256px;
            height: 61px;
            background: url(../../img/common/ANlogomain-small.png);
        }
    }
    
    .menu{
        background: #f9f9f9 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 3px rgba($color: #000000, $alpha: 0.29);;
        position: relative;
        z-index: 100;
        ul {
            margin: 0 auto;
            width: 100%;
            display: flex;
            justify-content:center;
            //mix-blend-mode: multiply;
            backdrop-filter: blur(50px);
            -webkit-backdrop-filter: blur(50px);
            @media(max-width:960px){
                flex-wrap: wrap;
            }
            li {
                display: block;
                width: calc( 100% / 8 );
                height: 70px;
                border-right: 1px solid #eee;
                @media (max-width: 1624px) {
                    width: 100%;
                }
                @media(max-width:960px){
                    width: 100%;
                    
                }
                &:first-child{
                    border-left: 1px solid #eee;
                }
                a {
                    font-size: 1rem;
                    display: block;
                    width: 100%;
                    height: 70px;
                    line-height: 70px;
                    text-align: center;
                    position: relative;
                    transition: 0.5s all;
                    @media (max-width: 1624px) {
                        width: 100%;
                    }
                    &::after{
                        content: "";
                        display: block;
                        width: 0px;
                        height: 3px;
                        position:absolute;
                        bottom: 0;
                        left:50%;
                        background: #B157FF;
                    }
                    &.now::after{
                        content: "";
                        display: block;
                        width: 100%;
                        height: 6px;
                        position:absolute;
                        bottom: 0;
                        left:0;
                        background: #B157FF;   
                    }
                    &:hover{
                        background: rgba(255,255,255,0.95);
                        position: relative;
                        transition: 0.5s all;
                        &::after{
                            content: "";
                            display: block;
                            width: 100%;
                            height: 6px;
                            position:absolute;
                            bottom: 0;
                            left:0;
                            transition: 0.3s all ease-out;
                            background: #B157FF;
                        }
                    }
                }
            }
            
        }
    }
}