@charset 'utf-8';
.p-pagetitle{
    width: 100%;
    max-height: 300px;
    &::before{
        content: "";
        display: block;
        background: url(../../img/common/title-bg.png)no-repeat;
        background-size: contain;
        width: 100%;
        max-width: 398px;
        aspect-ratio:1/1;
        @media (max-width:960px) {
            padding-top: 0px;
            width: 200px;
            height: 200px;
            background-size:contain;
        }
        

    }
    position: relative;
    font-size: clamp(2rem,3rem,5rem);
    font-weight: bold;
    color: #FFFFFF;
    text-shadow: 0px 3px 6px rgba(0,0,0,0.39);
    opacity: 1;
    line-height: 5rem;
    @media (max-width:960px) {
        font-size: 2rem;
        line-height: 2rem;    
        padding-top: 0;
    }
    span{
        position: absolute;
        top: 50%;
        left: 10%;
        @media (max-width:960px) {
            top:82px;
            left: 74px;
        }
    }
}