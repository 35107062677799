@charset 'utf-8';
.u-mb{
    margin-bottom: 1rem;
}
.u-mb-l{
    margin-bottom: 3rem;
}
.u-mb-xl{
    margin-bottom: 10rem;
}
