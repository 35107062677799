@charset 'utf-8';

.p-productbox {
    img {
        border-bottom: 3px solid #B157FF;
        border-radius: 5px 5px 0 0;
    }

    width: 193px;
    height: 255px;
    margin-left: 27px;
    margin-bottom: 27px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 4px #00000029;
    opacity: 1;
    border-radius: 5px;

    p {
        font-size: 1rem;
        font-weight: bold;
        padding: 8px 10px;
        text-align: justify;
        text-justify: inter-character;
        font-feature-settings: "palt";
        letter-spacing: -0.02em;
    }
}