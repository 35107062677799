@charset 'utf-8';
.p-newsheadline{
    color:#707070;
    li{
        list-style:none;
        padding-left: 7rem;
        p{
            position: relative;
        }
        span{
            position: absolute;
            margin-left: 0;
            font-weight: bold;
            left: -7rem;
         }
    }
}